<template>
    <div class="flex column al-center">
        <div class="lines-wrapper">
            <svg width="72" height="93" viewBox="0 0 72 93" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g id="Group 2">
                    <path id="fill-3" fill-rule="evenodd" clip-rule="evenodd"
                        d="M18.3748 20.3178H52.6672C58.9825 20.3178 64.0997 19.7828 64.0997 19.1258C64.0997 18.4638 58.9825 17.9287 52.6672 17.9287H18.3748C12.062 17.9287 6.94238 18.4638 6.94238 19.1258C6.94238 19.7828 12.062 20.3178 18.3748 20.3178Z"
                        fill="white" />
                    <path id="fill-4" fill-rule="evenodd" clip-rule="evenodd"
                        d="M16.657 29.2837H54.4814C61.4457 29.2837 67.0921 28.7486 67.0921 28.0916C67.0921 27.4296 61.4457 26.8945 54.4814 26.8945H16.657C9.69267 26.8945 4.04883 27.4296 4.04883 28.0916C4.04883 28.7486 9.69267 29.2837 16.657 29.2837Z"
                        fill="white" />
                    <path id="fill-12" fill-rule="evenodd" clip-rule="evenodd"
                        d="M16.2181 65.1384H54.4544C61.4936 65.1384 67.1998 64.6033 67.1998 63.9438C67.1998 63.2843 61.4936 62.7493 54.4544 62.7493H16.2181C9.1789 62.7493 3.47266 63.2843 3.47266 63.9438C3.47266 64.6033 9.1789 65.1384 16.2181 65.1384Z"
                        fill="white" />
                    <path id="fill-14" fill-rule="evenodd" clip-rule="evenodd"
                        d="M64.192 72.9081C64.192 72.2461 59.0125 71.7161 52.6273 71.7161H17.928C11.5428 71.7161 6.36328 72.2461 6.36328 72.9081C6.36328 73.5701 11.5428 74.1027 17.928 74.1027H52.6273C59.0125 74.1027 64.192 73.5701 64.192 72.9081Z"
                        fill="white" />
                    <path id="fill-16" fill-rule="evenodd" clip-rule="evenodd"
                        d="M60.5371 81.8713C60.5371 81.2118 56.049 80.6792 50.5125 80.6792H20.4387C14.9022 80.6792 10.4141 81.2118 10.4141 81.8713C10.4141 82.5333 14.9022 83.0658 20.4387 83.0658H50.5125C56.049 83.0658 60.5371 82.5333 60.5371 81.8713Z"
                        fill="white" />
                    <path id="fill-18" fill-rule="evenodd" clip-rule="evenodd"
                        d="M55.8968 90.8342C55.8968 90.1747 52.2374 89.6421 47.7268 89.6421H23.212C18.7014 89.6421 15.042 90.1747 15.042 90.8342C15.042 91.4961 18.7014 92.0287 23.212 92.0287H47.7268C52.2374 92.0287 55.8968 91.4961 55.8968 90.8342Z"
                        fill="white" />
                    <path id="fill-6" fill-rule="evenodd" clip-rule="evenodd"
                        d="M15.2506 38.2468H55.7959C63.2594 38.2468 69.3101 37.7142 69.3101 37.0547C69.3101 36.3927 63.2594 35.8577 55.7959 35.8577H15.2506C7.78704 35.8577 1.73633 36.3927 1.73633 37.0547C1.73633 37.7142 7.78704 38.2468 15.2506 38.2468Z"
                        fill="white" />
                    <path id="fill-10" fill-rule="evenodd" clip-rule="evenodd"
                        d="M1.73633 54.9795C1.73633 55.6415 7.78705 56.174 15.2506 56.174H55.7959C63.2594 56.174 69.3101 55.6415 69.3101 54.9795C69.3101 54.3175 63.2594 53.7849 55.7959 53.7849H15.2506C7.78705 53.7849 1.73633 54.3175 1.73633 54.9795Z"
                        fill="white" />
                    <path id="fill-8" fill-rule="evenodd" clip-rule="evenodd"
                        d="M14.2082 47.3435H56.8328C64.6807 47.3435 71.041 46.8084 71.041 46.1489C71.041 45.4894 64.6807 44.9543 56.8328 44.9543H14.2082C6.36273 44.9543 0 45.4894 0 46.1489C0 46.8084 6.36273 47.3435 14.2082 47.3435Z"
                        fill="white" />
                    <path id="fill-2" fill-rule="evenodd" clip-rule="evenodd"
                        d="M22.7676 11.3547H47.4747C54.2992 11.3547 59.8307 10.8196 59.8307 10.1601C59.8307 9.50064 54.2992 8.96558 47.4747 8.96558H22.7676C15.9456 8.96558 10.4141 9.50064 10.4141 10.1601C10.4141 10.8196 15.9456 11.3547 22.7676 11.3547Z"
                        fill="white" />
                    <path id="fill-1" fill-rule="evenodd" clip-rule="evenodd"
                        d="M28.5188 2.39163H41.9956C49.4392 2.39163 55.4749 1.85656 55.4749 1.19706C55.4749 0.535073 49.4392 0 41.9956 0H28.5188C21.0777 0 15.042 0.535073 15.042 1.19706C15.042 1.85656 21.0777 2.39163 28.5188 2.39163Z"
                        fill="white" />
                    <path id="fill-5" fill-rule="evenodd" clip-rule="evenodd"
                        d="M31.3605 31.376C26.82 31.376 23.1406 31.911 23.1406 32.5681C23.1406 33.23 26.82 33.7651 31.3605 33.7651H39.5804C44.1209 33.7651 47.8003 33.23 47.8003 32.5681C47.8003 31.911 44.1209 31.376 39.5804 31.376H31.3605Z"
                        fill="white" />
                    <path id="fill-7" fill-rule="evenodd" clip-rule="evenodd"
                        d="M30.4739 40.3391C25.147 40.3391 20.8262 40.8742 20.8262 41.5337C20.8262 42.1932 25.147 42.7282 30.4739 42.7282H40.1216C45.4509 42.7282 49.7693 42.1932 49.7693 41.5337C49.7693 40.8742 45.4509 40.3391 40.1216 40.3391H30.4739Z"
                        fill="white" />
                    <path id="fill-9" fill-rule="evenodd" clip-rule="evenodd"
                        d="M41.4266 51.6938C47.9141 51.6938 53.1735 51.1588 53.1735 50.4993C53.1735 49.8373 47.9141 49.3047 41.4266 49.3047H29.6796C23.192 49.3047 17.9326 49.8373 17.9326 50.4993C17.9326 51.1588 23.192 51.6938 29.6796 51.6938H41.4266Z"
                        fill="white" />
                    <path id="fill-11" fill-rule="evenodd" clip-rule="evenodd"
                        d="M28.5628 58.2676C21.4162 58.2676 15.6201 58.8026 15.6201 59.4621C15.6201 60.1241 21.4162 60.6567 28.5628 60.6567H41.5079C48.6544 60.6567 54.4505 60.1241 54.4505 59.4621C54.4505 58.8026 48.6544 58.2676 41.5079 58.2676H28.5628Z"
                        fill="white" />
                    <path id="fill-13" fill-rule="evenodd" clip-rule="evenodd"
                        d="M29.2632 67.2329C22.6883 67.2329 17.3564 67.768 17.3564 68.4275C17.3564 69.087 22.6883 69.622 29.2632 69.622H41.1724C47.7473 69.622 53.0791 69.087 53.0791 68.4275C53.0791 67.768 47.7473 67.2329 41.1724 67.2329H29.2632Z"
                        fill="white" />
                    <path id="fill-15" fill-rule="evenodd" clip-rule="evenodd"
                        d="M40.2873 78.5879C45.9811 78.5879 50.5965 78.0528 50.5965 77.3933C50.5965 76.7313 45.9811 76.1963 40.2873 76.1963H29.9781C24.2844 76.1963 19.6689 76.7313 19.6689 77.3933C19.6689 78.0528 24.2844 78.5879 29.9781 78.5879H40.2873Z"
                        fill="white" />
                    <path id="fill-17" fill-rule="evenodd" clip-rule="evenodd"
                        d="M39.7761 87.5488C44.5288 87.5488 48.3829 87.0162 48.3829 86.3567C48.3829 85.6947 44.5288 85.1597 39.7761 85.1597H31.1693C26.4166 85.1597 22.5625 85.6947 22.5625 86.3567C22.5625 87.0162 26.4166 87.5488 31.1693 87.5488H39.7761Z"
                        fill="white" />
                </g>
            </svg>
        </div>


        <div class="title-1-wrapper ov-hidden">
            <svg width="105" height="16" viewBox="0 0 105 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g id="title-1">
                    <g id="title-1-inner">
                        <path id="a1"
                            d="M5.7958 1.00708H8.0223L14.6833 14.9301H12.4197L11.5105 12.9301H7.2987C6.6679 12.9301 6.105 12.9731 5.61026 13.0591C5.12784 13.1441 4.71346 13.2541 4.36711 13.3891C4.02077 13.5241 3.74245 13.6701 3.53217 13.8291C3.32189 13.9881 3.17964 14.1411 3.10542 14.2881L2.75289 14.9301H0.563477L6.5565 2.69508L5.7958 1.00708ZM10.6014 10.9131L7.577 5.13508L4.51555 11.6831C4.76294 11.4751 5.17114 11.2981 5.74014 11.1511C6.3091 10.9921 7.076 10.9131 8.0409 10.9131H10.6014Z"
                            fill="white" />
                        <path id="l1" d="M17.2725 14.93V1.24603H19.2949V12.93H26.9764V14.93H17.2725Z" fill="white" />
                        <path id="l2" d="M29.3945 14.93V1.24603H31.417V12.93H39.0985V14.93H29.3945Z" fill="white" />
                        <path id="i" d="M41.5164 14.93V1.24603H43.5017V14.93H41.5164Z" fill="white" />
                        <path id="a2"
                            d="M51.1488 1.00708H53.3753L60.0363 14.9301H57.7727L56.8635 12.9301H52.6517C52.0209 12.9301 51.4581 12.9731 50.9633 13.0591C50.4809 13.1441 50.0665 13.2541 49.7201 13.3891C49.3738 13.5241 49.0955 13.6701 48.8852 13.8291C48.6749 13.9881 48.5327 14.1411 48.4584 14.2881L48.1059 14.9301H45.9165L51.9095 2.69508L51.1488 1.00708ZM55.9544 10.9131L52.93 5.13508L49.8686 11.6831C50.116 11.4751 50.5242 11.2981 51.0932 11.1511C51.6622 10.9921 52.4291 10.9131 53.3939 10.9131H55.9544Z"
                            fill="white" />
                        <path id="n"
                            d="M61.4194 1.24603H64.0356L72.9231 11.701V1.24603H74.9641V14.93H72.9602L65.0189 5.52003V14.93H63.0336V3.17203L61.4194 1.24603Z"
                            fill="white" />
                        <path id="c"
                            d="M89.5994 4.51104C89.0799 3.99704 88.4737 3.59404 87.7811 3.30004C87.0884 3.00704 86.3462 2.86004 85.5545 2.86004C84.7876 2.86004 84.0702 2.98804 83.4022 3.24504C82.7343 3.50204 82.1529 3.86904 81.6581 4.34604C81.1757 4.81104 80.7923 5.36704 80.5078 6.01504C80.2356 6.65104 80.0996 7.35404 80.0996 8.12504C80.0996 8.85804 80.248 9.54304 80.5449 10.179C80.8417 10.803 81.2437 11.341 81.7509 11.793C82.2581 12.246 82.8394 12.6 83.495 12.857C84.1506 13.114 84.8433 13.242 85.5731 13.242C86.4761 13.242 87.3048 13.053 88.0594 12.674C88.8139 12.282 89.4509 11.756 89.9705 11.096L91.2136 12.765C90.5085 13.536 89.6674 14.141 88.6902 14.581C87.7254 15.022 86.6802 15.242 85.5545 15.242C84.5031 15.242 83.5259 15.052 82.623 14.673C81.72 14.294 80.9345 13.774 80.2666 13.114C79.5986 12.453 79.0729 11.677 78.6894 10.784C78.306 9.87903 78.1143 8.91304 78.1143 7.88604C78.1143 6.88304 78.3122 5.95404 78.708 5.09804C79.1162 4.23004 79.6604 3.48404 80.3408 2.86004C81.0335 2.22404 81.8313 1.72904 82.7343 1.37404C83.6373 1.02004 84.5835 0.842041 85.5731 0.842041C86.6245 0.842041 87.6017 1.03804 88.5047 1.42904C89.42 1.80804 90.2179 2.33404 90.8982 3.00704L89.5994 4.51104Z"
                            fill="white" />
                        <path id="e"
                            d="M96.2119 12.93H104.747V14.93H94.1895V1.24603H103.931V3.26403H96.2119V7.40903C96.6329 7.16503 97.0589 7.01803 97.4919 6.96903C97.9249 6.90803 98.3709 6.87703 98.8279 6.87703H101.853V8.91303H98.8279C98.3829 8.91303 97.9379 8.95003 97.4919 9.02303C97.0589 9.08403 96.6329 9.22503 96.2119 9.44503V12.93Z"
                            fill="white" />
                    </g>
                </g>
            </svg>
        </div>

        <svg width="113" height="5" viewBox="0 0 113 5" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g id="Group 1">
                <path id="Vector" fill-rule="evenodd" clip-rule="evenodd"
                    d="M112.982 4.67601C94.6678 1.72301 75.9316 0.187012 56.8683 0.187012C37.8026 0.187012 19.0663 1.72301 0.751953 4.67601C19.1986 2.59501 37.9174 1.52902 56.8683 1.52902C75.8168 1.52902 94.5355 2.59501 112.982 4.67601Z"
                    fill="white" />
            </g>
        </svg>

        <div class="title-2-wrapper ov-hidden">

            <svg width="57" height="11" viewBox="0 0 57 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g id="title-2">
                    <g id="title-2-inner">
                        <path id="g"
                            d="M9.05882 3.07198C8.67942 2.69198 8.23662 2.39397 7.73072 2.17697C7.22472 1.96097 6.68262 1.85197 6.10442 1.85197C5.54422 1.85197 5.02022 1.94696 4.53232 2.13696C4.04452 2.32596 3.61982 2.59697 3.25842 2.94997C2.90612 3.29297 2.62602 3.70397 2.41822 4.18297C2.21942 4.65297 2.12002 5.17197 2.12002 5.74197C2.12002 6.28397 2.22842 6.78997 2.44532 7.25897C2.66212 7.71997 2.95572 8.11797 3.32622 8.45197C3.69662 8.78596 4.12132 9.04798 4.60012 9.23798C5.07892 9.42798 5.58492 9.52296 6.11802 9.52296C6.63292 9.52296 7.11632 9.43697 7.56812 9.26497C8.01982 9.09397 8.42192 8.85397 8.77422 8.54697V6.70396H7.47322L6.83622 5.23997H10.2379V9.16997C9.72292 9.73997 9.10852 10.187 8.39482 10.512C7.69002 10.837 6.92662 11 6.10442 11C5.33642 11 4.62272 10.86 3.96312 10.58C3.30362 10.3 2.72992 9.91596 2.24202 9.42796C1.75412 8.93996 1.37012 8.36597 1.09002 7.70697C0.810021 7.03797 0.669922 6.32397 0.669922 5.56497C0.669922 4.82497 0.814521 4.13797 1.10362 3.50497C1.40172 2.86397 1.79932 2.31297 2.29622 1.85197C2.80222 1.38197 3.38492 1.01597 4.04452 0.753967C4.70402 0.491967 5.39522 0.360962 6.11802 0.360962C6.88592 0.360962 7.59972 0.505968 8.25922 0.794968C8.92782 1.07497 9.51062 1.46397 10.0075 1.96097L9.05882 3.07198Z"
                            fill="white" />
                        <path id="r"
                            d="M14.2199 5.49794C14.5271 5.31694 14.8388 5.20894 15.155 5.17194C15.4713 5.12694 15.7965 5.10493 16.1308 5.10493H18.0552C18.5702 5.10493 18.9858 4.98294 19.302 4.73894C19.6183 4.49494 19.7764 4.10194 19.7764 3.55994C19.7764 3.29794 19.7312 3.07594 19.6409 2.89594C19.5505 2.71494 19.4285 2.56994 19.2749 2.46194C19.1214 2.34394 18.9407 2.26294 18.7329 2.21794C18.525 2.16394 18.2992 2.13693 18.0552 2.13693H14.2199V5.49794ZM17.3099 6.58194H16.1308C15.8055 6.58194 15.4803 6.60893 15.155 6.66293C14.8388 6.70793 14.5271 6.81194 14.2199 6.97495V10.7699H12.7427V0.658936H18.0552C18.516 0.658936 18.9407 0.726946 19.3292 0.862946C19.7177 0.988946 20.0519 1.17895 20.332 1.43195C20.6211 1.68495 20.8425 1.99694 20.9961 2.36694C21.1587 2.73794 21.24 3.16195 21.24 3.64095C21.24 4.40895 21.0322 5.03693 20.6166 5.52493C20.2101 6.01293 19.6409 6.34694 18.909 6.52794L19.6951 8.13994C19.8577 8.37494 19.9977 8.56495 20.1152 8.70995C20.2326 8.85395 20.3456 8.97194 20.454 9.06194C20.5624 9.14294 20.6754 9.20195 20.7928 9.23795C20.9103 9.26495 21.0503 9.27895 21.2129 9.27895L21.6873 9.26494V10.7699C21.3981 10.7699 21.1045 10.7599 20.8064 10.7419C20.5172 10.7239 20.2372 10.6659 19.9661 10.5659C19.6951 10.4669 19.4376 10.3089 19.1936 10.0919C18.9497 9.87493 18.7329 9.56794 18.5431 9.16994L17.3099 6.58194Z"
                            fill="white" />
                        <path id="o"
                            d="M32.8657 5.64697C32.8657 5.09597 32.7573 4.58997 32.5405 4.12897C32.3327 3.65897 32.0435 3.25697 31.6731 2.92297C31.3117 2.57897 30.8916 2.31696 30.4127 2.13696C29.9339 1.94696 29.4234 1.85197 28.8813 1.85197C28.3212 1.85197 27.7971 1.94696 27.3093 2.13696C26.8214 2.32596 26.3967 2.59697 26.0353 2.94997C25.683 3.29297 25.4029 3.70397 25.1951 4.18297C24.9963 4.65297 24.8969 5.17197 24.8969 5.74197C24.8969 6.28397 25.0054 6.78997 25.2222 7.25897C25.439 7.71997 25.7327 8.11797 26.1031 8.45197C26.4735 8.78596 26.8982 9.04798 27.377 9.23798C27.8559 9.42798 28.3618 9.52296 28.8949 9.52296C29.437 9.52296 29.9474 9.42298 30.4263 9.22498C30.9142 9.02598 31.3343 8.75496 31.6867 8.41096C32.0481 8.05896 32.3327 7.64796 32.5405 7.17796C32.7573 6.70796 32.8657 6.19797 32.8657 5.64697ZM34.3294 5.72797C34.3294 6.47797 34.1803 7.17397 33.8821 7.81497C33.593 8.45697 33.2 9.01196 32.7031 9.48196C32.2062 9.95196 31.6279 10.322 30.9684 10.593C30.3088 10.864 29.6132 11 28.8813 11C28.1134 11 27.3996 10.86 26.7401 10.58C26.0805 10.3 25.5068 9.91596 25.0189 9.42796C24.531 8.93996 24.147 8.36597 23.867 7.70697C23.5869 7.03797 23.4468 6.32397 23.4468 5.56497C23.4468 4.82497 23.5914 4.13797 23.8805 3.50497C24.1787 2.86397 24.5762 2.31297 25.0731 1.85197C25.5791 1.38197 26.1618 1.01597 26.8214 0.753967C27.4809 0.491967 28.1721 0.360962 28.8949 0.360962C29.6448 0.360962 30.3495 0.500975 31.009 0.780975C31.6686 1.05198 32.2423 1.43197 32.7302 1.91997C33.2271 2.39897 33.6156 2.96297 33.8957 3.61397C34.1848 4.26397 34.3294 4.96897 34.3294 5.72797Z"
                            fill="white" />
                        <path id="u"
                            d="M41.0357 10.9999C39.5901 10.9999 38.4698 10.6389 37.6747 9.91594C36.8886 9.19294 36.4956 8.16694 36.4956 6.83894V0.658936H37.9593V6.83894C37.9593 7.71594 38.2258 8.38394 38.7589 8.84494C39.2919 9.29694 40.0509 9.52293 41.0357 9.52293C42.0205 9.52293 42.7794 9.29694 43.3125 8.84494C43.8455 8.38394 44.112 7.71594 44.112 6.83894V0.658936H45.5757V6.83894C45.5757 8.16694 45.1782 9.19294 44.3831 9.91594C43.5971 10.6389 42.4812 10.9999 41.0357 10.9999Z"
                            fill="white" />
                        <path id="p"
                            d="M53.6138 4.98294C54.6077 4.98294 55.1046 4.50394 55.1046 3.54594C55.1046 3.03094 54.9781 2.66994 54.7251 2.46194C54.4721 2.24494 54.1017 2.13693 53.6138 2.13693H49.9005V5.38895C50.2077 5.20895 50.5194 5.09594 50.8356 5.04994C51.1608 5.00494 51.4906 4.98294 51.8249 4.98294H53.6138ZM48.4233 10.7699V0.658936H53.6138C54.0565 0.658936 54.4586 0.726946 54.82 0.862946C55.1904 0.988946 55.5021 1.17895 55.7551 1.43195C56.0171 1.67595 56.2159 1.97894 56.3514 2.33994C56.496 2.69194 56.5682 3.09394 56.5682 3.54594C56.5682 3.99794 56.496 4.40395 56.3514 4.76595C56.2159 5.11795 56.0171 5.42494 55.7551 5.68694C55.5021 5.93994 55.1904 6.13494 54.82 6.26994C54.4586 6.40594 54.0565 6.47295 53.6138 6.47295H51.8249C51.4997 6.47295 51.1744 6.50093 50.8491 6.55493C50.5239 6.60893 50.2077 6.71694 49.9005 6.87994V10.7699H48.4233Z"
                            fill="white" />
                    </g>
                </g>
            </svg>
        </div>



    </div>



</template>

<style lang="scss">
.title-1-wrapper {
    margin-bottom: 6px;
    margin-top: 17px;
    will-change: transform;
}

.title-2-wrapper {
    margin-top: 6px;
    will-change: transform;

}
</style>