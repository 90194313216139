/**
 * @author Irakli Kandelaki
 */

import { ref } from "vue";
import type { Ref } from "vue";
import { lerpFn } from "./lerpFn";




export const mouseFx = (store: any): void => {
    // EL SPECS
    const cursorEl = document.querySelector<HTMLElement>(".cursor");
    const cursorElInner = document.querySelector<HTMLElement>(".cursor__inner");
    const cursorElLeftArrow = document.querySelector<HTMLElement>(
        ".cursor__left-arrow"
    );
    const cursorElRightArrow = document.querySelector<HTMLElement>(
        ".cursor__right-arrow"
    );

    const cursorElCancel = document.querySelector<HTMLElement>(
        ".cursor__cancel"
    );
    const cursorElText = document.querySelector<HTMLElement>(".cursor__text");

    const cursorRadius: number = 131;
    let scaleVal: number = 0.167;

    // MOUSE EVENT TARGET
    let eTarget: null | HTMLElement = null;

    // REFS
    const oldCoordX: Ref<number> = ref(window.innerWidth / 2);
    const oldCoordY: Ref<number> = ref(window.innerHeight / 2);

    const newCoordX: Ref<number> = ref(window.innerWidth / 2);
    const newCoordY: Ref<number> = ref(window.innerHeight / 2);

    // METHODS


    const setNewCoords = (e: MouseEvent): void => {
        newCoordX.value = e.clientX;
        newCoordY.value = e.clientY;
        // if (!(e.target instanceof HTMLElement)) return;
        //@ts-ignore
        eTarget = e.target;
    };
    const updateMousePos = (): void => {
        cursorEl.style.transform = `translate3d(${oldCoordX.value - cursorRadius / 2
            }px, ${oldCoordY.value - cursorRadius / 2}px, 0px)`;
        cursorElInner.style.transform = ` scale(${scaleVal}`;
        if (scaleVal > 0.167) {
            if (store.isDark) {
                cursorElInner.style.backgroundColor = 'rgba(255, 255, 255, 0.2)';

            } else {

                cursorElInner.style.backgroundColor = 'rgba(24, 34, 54, 0.2)';
            }
        } else {

            if (store.isDark) {
                cursorElInner.style.backgroundColor = 'rgba(255, 255, 255, 0.5)';

            } else {

                cursorElInner.style.backgroundColor = 'rgba(24, 34, 54, 0.5)';

            }



        }
    };



    window.addEventListener('mousedown', () => {
        store.isClicking = true;
        cursorElInner.classList.add('is-clicking');
        cursorEl.classList.add('is-clicking-parent');



    })

    window.addEventListener('mouseup', () => {
        store.isClicking = false;

        cursorElInner.classList.remove('is-clicking');
        cursorEl.classList.remove('is-clicking-parent');




    })


    const handleArrow = (
        arrowState: number | null,
        direction?: string | null,
        text?: string | null
    ): void => {

        // HANDLE ARROW STATES
        if (arrowState === 1 && direction === "left") {
            cursorElLeftArrow.classList.add("show-mouse-arrow");
        } else if (arrowState === 0 && direction === "left") {
            cursorElLeftArrow.classList.remove("show-mouse-arrow");
        } else if (arrowState === 1 && direction === "right") {
            cursorElRightArrow.classList.add("show-mouse-arrow");
        } else if (arrowState === 0 && direction === "right") {
            cursorElRightArrow.classList.remove("show-mouse-arrow");
        } else if (text !== null && text?.length) {
            cursorElRightArrow.classList.remove("show-mouse-arrow");
            cursorElLeftArrow.classList.remove("show-mouse-arrow");
            cursorElText.classList.add("show-text");
            cursorElText.innerHTML = text;
        } else {
            cursorElText.classList.remove("show-text");
        }

    };

    const setMousePos = (): void => {
        // cursorEl.setAttribute('data-component-name', eTarget.dataset.componentName);

        oldCoordX.value = lerpFn(oldCoordX.value, newCoordX.value, 0.15);
        oldCoordY.value = lerpFn(oldCoordY.value, newCoordY.value, 0.15);

        if (eTarget !== null && eTarget?.dataset) {
            cursorEl.dataset.componentName = eTarget.dataset.componentName;

            if (eTarget.dataset.scaleType) {
                scaleVal = Number(eTarget.dataset.scaleType);


            } else if (store.cursorComponent === 'slider') {
                scaleVal = 0.8;

            }

            else if (store.cursorComponent === 'play') {
                scaleVal = 1;

            }
            else {
                scaleVal = 0.167;
            }

            if (!eTarget.dataset.mouseText) {
                cursorElText.classList.remove("show-text");
            }

            // MAIN SLIDER CURSOR HANDLER
            if (eTarget.dataset.componentName === "main-slider") {

                if (newCoordX.value < eTarget.offsetWidth * 0.2) {

                    handleArrow(1, "left", null);
                } else {
                    handleArrow(0, "left", null);
                }

                if (newCoordX.value > eTarget.offsetWidth * 0.8) {
                    handleArrow(1, "right", null);
                } else {
                    handleArrow(0, "right", null);
                }

                if (
                    newCoordX.value >= eTarget.offsetWidth * 0.2 &&
                    newCoordX.value <= eTarget.offsetWidth * 0.8
                ) {
                    handleArrow(null, null, eTarget.dataset.mouseText);
                    cursorElInner.classList.add('border-gray')
                } else {
                    handleArrow(null, null, null);
                    cursorElInner.classList.remove('border-gray')
                }


            } else {
                cursorElRightArrow.classList.remove("show-mouse-arrow");
                cursorElLeftArrow.classList.remove("show-mouse-arrow");
            }

            // ZOOM CANCEL CURSOR HANDLER


            // if (store.cursorComponent === "zoom-cancel") {
            //     cursorElInner.classList.add('border-gray')
            //     cursorElCancel.classList.add("show-cancel");

            // } else {
            //     cursorElInner.classList.remove('border-gray')
            //     cursorElCancel.classList.remove("show-cancel");


            // }

            // HIDE CURSOR CIRCLE


            if (eTarget.dataset.cursorType === "hide" || eTarget.classList.contains('el-switch__input') || eTarget.classList.contains('el-switch__core') || 
            eTarget.classList.contains('el-switch__action')) {
                cursorElInner.classList.add('hide-cursor')

            } else {
                cursorElInner.classList.remove('hide-cursor')



            }

            if (store.cursorComponent === 'slider') {
                cursorEl.classList.add('is-slider')

                cursorElInner.classList.add('slider-cursor')


            } else {
                cursorElInner.classList.remove('slider-cursor')
                cursorEl.classList.remove('is-slider')

            }




        }
        updateMousePos();
        window.requestAnimationFrame(setMousePos);
    };

    updateMousePos();

    window.requestAnimationFrame(setMousePos);

    window.addEventListener("mousemove", setNewCoords);
};


