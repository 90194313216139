<template>
    <svg width="113" height="151" viewBox="0 0 113 151" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g id="Group-1">
            <path id="Vector" fill-rule="evenodd" clip-rule="evenodd"
                d="M112.982 134.676C94.6678 131.723 75.9316 130.187 56.8683 130.187C37.8026 130.187 19.0663 131.723 0.751953 134.676C19.1986 132.595 37.9174 131.529 56.8683 131.529C75.8168 131.529 94.5355 132.595 112.982 134.676Z"
                fill="white" />
            <g id="title-1">
                <path id="a1"
                    d="M9.7958 109.007H12.0223L18.6833 122.93H16.4197L15.5105 120.93H11.2987C10.6679 120.93 10.105 120.973 9.61026 121.059C9.12784 121.144 8.71346 121.254 8.36711 121.389C8.02077 121.524 7.74245 121.67 7.53217 121.829C7.32189 121.988 7.17964 122.141 7.10542 122.288L6.75289 122.93H4.56348L10.5565 110.695L9.7958 109.007ZM14.6014 118.913L11.577 113.135L8.51555 119.683C8.76294 119.475 9.17114 119.298 9.74014 119.151C10.3091 118.992 11.076 118.913 12.0409 118.913H14.6014Z"
                    fill="white" />
                <path id="l1" d="M21.2725 122.93V109.246H23.2949V120.93H30.9764V122.93H21.2725Z" fill="white" />
                <path id="l2" d="M33.3944 122.93V109.246H35.4169V120.93H43.0984V122.93H33.3944Z" fill="white" />
                <path id="i" d="M45.5164 122.93V109.246H47.5017V122.93H45.5164Z" fill="white" />
                <path id="a2"
                    d="M55.1488 109.007H57.3753L64.0363 122.93H61.7727L60.8635 120.93H56.6517C56.0209 120.93 55.4581 120.973 54.9633 121.059C54.4809 121.144 54.0665 121.254 53.7201 121.389C53.3738 121.524 53.0955 121.67 52.8852 121.829C52.6749 121.988 52.5327 122.141 52.4584 122.288L52.1059 122.93H49.9165L55.9095 110.695L55.1488 109.007ZM59.9544 118.913L56.93 113.135L53.8686 119.683C54.116 119.475 54.5242 119.298 55.0932 119.151C55.6622 118.992 56.4291 118.913 57.3939 118.913H59.9544Z"
                    fill="white" />
                <path id="n"
                    d="M65.4196 109.246H68.0358L76.9233 119.701V109.246H78.9643V122.93H76.9604L69.0191 113.52V122.93H67.0338V111.172L65.4196 109.246Z"
                    fill="white" />
                <path id="c"
                    d="M93.5992 112.511C93.0797 111.997 92.4735 111.594 91.7809 111.3C91.0882 111.007 90.346 110.86 89.5543 110.86C88.7874 110.86 88.07 110.988 87.402 111.245C86.7341 111.502 86.1527 111.869 85.6579 112.346C85.1755 112.811 84.7921 113.367 84.5076 114.015C84.2354 114.651 84.0994 115.354 84.0994 116.125C84.0994 116.858 84.2478 117.543 84.5447 118.179C84.8415 118.803 85.2435 119.341 85.7507 119.793C86.2579 120.246 86.8392 120.6 87.4948 120.857C88.1504 121.114 88.8431 121.242 89.5729 121.242C90.4759 121.242 91.3046 121.053 92.0592 120.674C92.8137 120.282 93.4507 119.756 93.9703 119.096L95.2134 120.765C94.5083 121.536 93.6672 122.141 92.69 122.581C91.7252 123.022 90.68 123.242 89.5543 123.242C88.5029 123.242 87.5257 123.052 86.6228 122.673C85.7198 122.294 84.9343 121.774 84.2664 121.114C83.5984 120.453 83.0727 119.677 82.6892 118.784C82.3058 117.879 82.1141 116.913 82.1141 115.886C82.1141 114.883 82.312 113.954 82.7078 113.098C83.116 112.23 83.6602 111.484 84.3406 110.86C85.0333 110.224 85.8311 109.729 86.7341 109.374C87.6371 109.02 88.5833 108.842 89.5729 108.842C90.6243 108.842 91.6015 109.038 92.5045 109.429C93.4198 109.808 94.2177 110.334 94.898 111.007L93.5992 112.511Z"
                    fill="white" />
                <path id="e"
                    d="M100.212 120.93H108.747V122.93H98.1896V109.246H107.931V111.264H100.212V115.409C100.633 115.165 101.059 115.018 101.492 114.969C101.925 114.908 102.371 114.877 102.828 114.877H105.853V116.913H102.828C102.383 116.913 101.938 116.95 101.492 117.023C101.059 117.084 100.633 117.225 100.212 117.445V120.93Z"
                    fill="white" />
            </g>
            <g id="title-2">
                <path id="g"
                    d="M37.0588 143.072C36.6794 142.692 36.2366 142.394 35.7307 142.177C35.2247 141.961 34.6826 141.852 34.1044 141.852C33.5442 141.852 33.0202 141.947 32.5323 142.137C32.0445 142.326 31.6198 142.597 31.2584 142.95C30.9061 143.293 30.626 143.704 30.4182 144.183C30.2194 144.653 30.12 145.172 30.12 145.742C30.12 146.284 30.2284 146.79 30.4453 147.259C30.6621 147.72 30.9557 148.118 31.3262 148.452C31.6966 148.786 32.1213 149.048 32.6001 149.238C33.0789 149.428 33.5849 149.523 34.118 149.523C34.6329 149.523 35.1163 149.437 35.5681 149.265C36.0198 149.094 36.4219 148.854 36.7742 148.547V146.704H35.4732L34.8362 145.24H38.2379V149.17C37.7229 149.74 37.1085 150.187 36.3948 150.512C35.69 150.837 34.9266 151 34.1044 151C33.3364 151 32.6227 150.86 31.9631 150.58C31.3036 150.3 30.7299 149.916 30.242 149.428C29.7541 148.94 29.3701 148.366 29.09 147.707C28.81 147.038 28.6699 146.324 28.6699 145.565C28.6699 144.825 28.8145 144.138 29.1036 143.505C29.4017 142.864 29.7993 142.313 30.2962 141.852C30.8022 141.382 31.3849 141.016 32.0445 140.754C32.704 140.492 33.3952 140.361 34.118 140.361C34.8859 140.361 35.5997 140.506 36.2592 140.795C36.9278 141.075 37.5106 141.464 38.0075 141.961L37.0588 143.072Z"
                    fill="white" />
                <path id="r"
                    d="M42.2198 145.498C42.527 145.317 42.8387 145.209 43.1549 145.172C43.4712 145.127 43.7964 145.105 44.1307 145.105H46.0551C46.5701 145.105 46.9857 144.983 47.3019 144.739C47.6182 144.495 47.7763 144.102 47.7763 143.56C47.7763 143.298 47.7311 143.076 47.6408 142.896C47.5504 142.715 47.4284 142.57 47.2748 142.462C47.1213 142.344 46.9406 142.263 46.7328 142.218C46.5249 142.164 46.2991 142.137 46.0551 142.137H42.2198V145.498ZM45.3098 146.582H44.1307C43.8054 146.582 43.4802 146.609 43.1549 146.663C42.8387 146.708 42.527 146.812 42.2198 146.975V150.77H40.7426V140.659H46.0551C46.5159 140.659 46.9406 140.727 47.3291 140.863C47.7176 140.989 48.0518 141.179 48.3319 141.432C48.621 141.685 48.8424 141.997 48.996 142.367C49.1586 142.738 49.2399 143.162 49.2399 143.641C49.2399 144.409 49.0321 145.037 48.6165 145.525C48.21 146.013 47.6408 146.347 46.9089 146.528L47.695 148.14C47.8576 148.375 47.9976 148.565 48.1151 148.71C48.2325 148.854 48.3455 148.972 48.4539 149.062C48.5623 149.143 48.6753 149.202 48.7927 149.238C48.9102 149.265 49.0502 149.279 49.2128 149.279L49.6872 149.265V150.77C49.398 150.77 49.1044 150.76 48.8063 150.742C48.5171 150.724 48.2371 150.666 47.966 150.566C47.695 150.467 47.4375 150.309 47.1935 150.092C46.9496 149.875 46.7328 149.568 46.543 149.17L45.3098 146.582Z"
                    fill="white" />
                <path id="o"
                    d="M60.8657 145.647C60.8657 145.096 60.7573 144.59 60.5405 144.129C60.3327 143.659 60.0435 143.257 59.6731 142.923C59.3117 142.579 58.8916 142.317 58.4127 142.137C57.9339 141.947 57.4234 141.852 56.8813 141.852C56.3212 141.852 55.7971 141.947 55.3093 142.137C54.8214 142.326 54.3967 142.597 54.0353 142.95C53.683 143.293 53.4029 143.704 53.1951 144.183C52.9963 144.653 52.8969 145.172 52.8969 145.742C52.8969 146.284 53.0054 146.79 53.2222 147.259C53.439 147.72 53.7327 148.118 54.1031 148.452C54.4735 148.786 54.8982 149.048 55.377 149.238C55.8559 149.428 56.3618 149.523 56.8949 149.523C57.437 149.523 57.9474 149.423 58.4263 149.225C58.9142 149.026 59.3343 148.755 59.6867 148.411C60.0481 148.059 60.3327 147.648 60.5405 147.178C60.7573 146.708 60.8657 146.198 60.8657 145.647ZM62.3294 145.728C62.3294 146.478 62.1803 147.174 61.8821 147.815C61.593 148.457 61.2 149.012 60.7031 149.482C60.2062 149.952 59.6279 150.322 58.9684 150.593C58.3088 150.864 57.6132 151 56.8813 151C56.1134 151 55.3996 150.86 54.7401 150.58C54.0805 150.3 53.5068 149.916 53.0189 149.428C52.531 148.94 52.147 148.366 51.867 147.707C51.5869 147.038 51.4468 146.324 51.4468 145.565C51.4468 144.825 51.5914 144.138 51.8805 143.505C52.1787 142.864 52.5762 142.313 53.0731 141.852C53.5791 141.382 54.1618 141.016 54.8214 140.754C55.4809 140.492 56.1721 140.361 56.8949 140.361C57.6448 140.361 58.3495 140.501 59.009 140.781C59.6686 141.052 60.2423 141.432 60.7302 141.92C61.2271 142.399 61.6156 142.963 61.8957 143.614C62.1848 144.264 62.3294 144.969 62.3294 145.728Z"
                    fill="white" />
                <path id="u"
                    d="M69.0358 151C67.5902 151 66.4699 150.639 65.6748 149.916C64.8887 149.193 64.4957 148.167 64.4957 146.839V140.659H65.9594V146.839C65.9594 147.716 66.2259 148.384 66.759 148.845C67.292 149.297 68.051 149.523 69.0358 149.523C70.0206 149.523 70.7795 149.297 71.3126 148.845C71.8456 148.384 72.1121 147.716 72.1121 146.839V140.659H73.5758V146.839C73.5758 148.167 73.1783 149.193 72.3832 149.916C71.5972 150.639 70.4813 151 69.0358 151Z"
                    fill="white" />
                <path id="p"
                    d="M81.6138 144.983C82.6077 144.983 83.1046 144.504 83.1046 143.546C83.1046 143.031 82.9781 142.67 82.7251 142.462C82.4721 142.245 82.1017 142.137 81.6138 142.137H77.9005V145.389C78.2077 145.209 78.5194 145.096 78.8356 145.05C79.1608 145.005 79.4906 144.983 79.8249 144.983H81.6138ZM76.4233 150.77V140.659H81.6138C82.0565 140.659 82.4586 140.727 82.82 140.863C83.1904 140.989 83.5021 141.179 83.7551 141.432C84.0171 141.676 84.2159 141.979 84.3514 142.34C84.496 142.692 84.5682 143.094 84.5682 143.546C84.5682 143.998 84.496 144.404 84.3514 144.766C84.2159 145.118 84.0171 145.425 83.7551 145.687C83.5021 145.94 83.1904 146.135 82.82 146.27C82.4586 146.406 82.0565 146.473 81.6138 146.473H79.8249C79.4997 146.473 79.1744 146.501 78.8491 146.555C78.5239 146.609 78.2077 146.717 77.9005 146.88V150.77H76.4233Z"
                    fill="white" />
            </g>
            <path id="fill-3" fill-rule="evenodd" clip-rule="evenodd"
                d="M40.3748 20.3178H74.6672C80.9825 20.3178 86.0997 19.7828 86.0997 19.1258C86.0997 18.4638 80.9825 17.9287 74.6672 17.9287H40.3748C34.062 17.9287 28.9424 18.4638 28.9424 19.1258C28.9424 19.7828 34.062 20.3178 40.3748 20.3178Z"
                fill="white" />
            <path id="fill-4" fill-rule="evenodd" clip-rule="evenodd"
                d="M38.657 29.2837H76.4814C83.4457 29.2837 89.0921 28.7486 89.0921 28.0916C89.0921 27.4296 83.4457 26.8945 76.4814 26.8945H38.657C31.6927 26.8945 26.0488 27.4296 26.0488 28.0916C26.0488 28.7486 31.6927 29.2837 38.657 29.2837Z"
                fill="white" />
            <path id="fill-12" fill-rule="evenodd" clip-rule="evenodd"
                d="M38.2181 65.1384H76.4544C83.4936 65.1384 89.1999 64.6033 89.1999 63.9438C89.1999 63.2843 83.4936 62.7493 76.4544 62.7493H38.2181C31.1789 62.7493 25.4727 63.2843 25.4727 63.9438C25.4727 64.6033 31.1789 65.1384 38.2181 65.1384Z"
                fill="white" />
            <path id="fill-14" fill-rule="evenodd" clip-rule="evenodd"
                d="M86.192 72.9081C86.192 72.2461 81.0125 71.7161 74.6273 71.7161H39.928C33.5428 71.7161 28.3633 72.2461 28.3633 72.9081C28.3633 73.5701 33.5428 74.1027 39.928 74.1027H74.6273C81.0125 74.1027 86.192 73.5701 86.192 72.9081Z"
                fill="white" />
            <path id="fill-16" fill-rule="evenodd" clip-rule="evenodd"
                d="M82.5371 81.8713C82.5371 81.2118 78.049 80.6792 72.5125 80.6792H42.4387C36.9022 80.6792 32.4141 81.2118 32.4141 81.8713C32.4141 82.5333 36.9022 83.0658 42.4387 83.0658H72.5125C78.049 83.0658 82.5371 82.5333 82.5371 81.8713Z"
                fill="white" />
            <path id="fill-18" fill-rule="evenodd" clip-rule="evenodd"
                d="M77.8968 90.8342C77.8968 90.1747 74.2374 89.6421 69.7268 89.6421H45.212C40.7014 89.6421 37.042 90.1747 37.042 90.8342C37.042 91.4961 40.7014 92.0287 45.212 92.0287H69.7268C74.2374 92.0287 77.8968 91.4961 77.8968 90.8342Z"
                fill="white" />
            <path id="fill-6" fill-rule="evenodd" clip-rule="evenodd"
                d="M37.2506 38.2468H77.7959C85.2594 38.2468 91.3101 37.7142 91.3101 37.0547C91.3101 36.3927 85.2594 35.8577 77.7959 35.8577H37.2506C29.787 35.8577 23.7363 36.3927 23.7363 37.0547C23.7363 37.7142 29.787 38.2468 37.2506 38.2468Z"
                fill="white" />
            <path id="fill-10" fill-rule="evenodd" clip-rule="evenodd"
                d="M23.7363 54.9795C23.7363 55.6415 29.787 56.174 37.2506 56.174H77.7959C85.2594 56.174 91.3101 55.6415 91.3101 54.9795C91.3101 54.3175 85.2594 53.7849 77.7959 53.7849H37.2506C29.787 53.7849 23.7363 54.3175 23.7363 54.9795Z"
                fill="white" />
            <path id="fill-8" fill-rule="evenodd" clip-rule="evenodd"
                d="M36.2082 47.3435H78.8328C86.6807 47.3435 93.041 46.8084 93.041 46.1489C93.041 45.4894 86.6807 44.9543 78.8328 44.9543H36.2082C28.3627 44.9543 22 45.4894 22 46.1489C22 46.8084 28.3627 47.3435 36.2082 47.3435Z"
                fill="white" />
            <path id="fill-2" fill-rule="evenodd" clip-rule="evenodd"
                d="M44.7676 11.3547H69.4747C76.2992 11.3547 81.8307 10.8196 81.8307 10.1601C81.8307 9.50064 76.2992 8.96558 69.4747 8.96558H44.7676C37.9456 8.96558 32.4141 9.50064 32.4141 10.1601C32.4141 10.8196 37.9456 11.3547 44.7676 11.3547Z"
                fill="white" />
            <path id="fill-1" fill-rule="evenodd" clip-rule="evenodd"
                d="M50.5188 2.39163H63.9956C71.4392 2.39163 77.4749 1.85656 77.4749 1.19706C77.4749 0.535073 71.4392 0 63.9956 0H50.5188C43.0777 0 37.042 0.535073 37.042 1.19706C37.042 1.85656 43.0777 2.39163 50.5188 2.39163Z"
                fill="white" />
            <path id="fill-5" fill-rule="evenodd" clip-rule="evenodd"
                d="M53.3605 31.376C48.82 31.376 45.1406 31.911 45.1406 32.5681C45.1406 33.23 48.82 33.7651 53.3605 33.7651H61.5804C66.1209 33.7651 69.8003 33.23 69.8003 32.5681C69.8003 31.911 66.1209 31.376 61.5804 31.376H53.3605Z"
                fill="white" />
            <path id="fill-7" fill-rule="evenodd" clip-rule="evenodd"
                d="M52.4739 40.3391C47.147 40.3391 42.8262 40.8742 42.8262 41.5337C42.8262 42.1932 47.147 42.7282 52.4739 42.7282H62.1216C67.4509 42.7282 71.7693 42.1932 71.7693 41.5337C71.7693 40.8742 67.4509 40.3391 62.1216 40.3391H52.4739Z"
                fill="white" />
            <path id="fill-9" fill-rule="evenodd" clip-rule="evenodd"
                d="M63.4266 51.6938C69.9141 51.6938 75.1735 51.1588 75.1735 50.4993C75.1735 49.8373 69.9141 49.3047 63.4266 49.3047H51.6796C45.192 49.3047 39.9326 49.8373 39.9326 50.4993C39.9326 51.1588 45.192 51.6938 51.6796 51.6938H63.4266Z"
                fill="white" />
            <path id="fill-11" fill-rule="evenodd" clip-rule="evenodd"
                d="M50.5628 58.2676C43.4162 58.2676 37.6201 58.8026 37.6201 59.4621C37.6201 60.1241 43.4162 60.6567 50.5628 60.6567H63.5079C70.6544 60.6567 76.4505 60.1241 76.4505 59.4621C76.4505 58.8026 70.6544 58.2676 63.5079 58.2676H50.5628Z"
                fill="white" />
            <path id="fill-13" fill-rule="evenodd" clip-rule="evenodd"
                d="M51.2632 67.2329C44.6883 67.2329 39.3564 67.768 39.3564 68.4275C39.3564 69.087 44.6883 69.622 51.2632 69.622H63.1724C69.7473 69.622 75.0791 69.087 75.0791 68.4275C75.0791 67.768 69.7473 67.2329 63.1724 67.2329H51.2632Z"
                fill="white" />
            <path id="fill-15" fill-rule="evenodd" clip-rule="evenodd"
                d="M62.2873 78.5879C67.9811 78.5879 72.5965 78.0528 72.5965 77.3933C72.5965 76.7313 67.9811 76.1963 62.2873 76.1963H51.9781C46.2844 76.1963 41.6689 76.7313 41.6689 77.3933C41.6689 78.0528 46.2844 78.5879 51.9781 78.5879H62.2873Z"
                fill="white" />
            <path id="fill-17" fill-rule="evenodd" clip-rule="evenodd"
                d="M61.7761 87.5488C66.5288 87.5488 70.3829 87.0162 70.3829 86.3567C70.3829 85.6947 66.5288 85.1597 61.7761 85.1597H53.1693C48.4166 85.1597 44.5625 85.6947 44.5625 86.3567C44.5625 87.0162 48.4166 87.5488 53.1693 87.5488H61.7761Z"
                fill="white" />
        </g>
    </svg>


</template>