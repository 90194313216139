<script setup>
import {computed, onMounted, ref} from 'vue';
import {useMenuStore} from '@/store/pinia/menu';
import {UseFavoriteFlats} from '@/store/pinia/FavoriteProjects';
import AllianceLogo from '../../UI/AllianceLogo.vue';
import {handleResize} from '@/composables/resizeHandler';
import LangToggler from './UI/LangToggler.vue';
import ThemeSwitch from './UI/ThemeSwitch.vue';


// INIT STORE
const store = useMenuStore();
const flatStore = UseFavoriteFlats();


const showLiveMenu = ref(false)
const showApartmentsMenu = ref(false)

let showBanner = ref(true)
// COMPOSABLES
const {windowWidth} = handleResize();

// COMPUTED
const headerMenuNew = computed(() => {
    const widgets = store?.indx?.widgets?.header_menu;
    const liveLinks = [];
    const apartmentLinks = [];
    if (!widgets) return;
    for (const [k, v] of Object.entries(widgets)) {
        if (k.includes('live-')) {
            liveLinks.push(v)
        }
        if (k.includes('apartments-')) {
          apartmentLinks.push(v)
        }
    }
  return {
    widgets,
    live_links: liveLinks,
    apartmentLinks: apartmentLinks,
  };
})

let notification = computed(() => {
    let item = store.indx?.widgets?.notification

    return {
        ...item,
        icon: item?.image?.[0]?.devices?.desktop
    }
})

// METHODS
const handleBurgerOpen = () => {
    store.isBurgerOpen = true;
}


</script>


<template>
    <header :class="{ 'header-scrolled': store.headerScrolled, 'header-opaque': store.isHeaderOpaque }" class="ev-none"
            @mouseenter="store.getComponentCursor($event, null)">
        <div v-if="showBanner" class="banner-header-pop-up">
            <div class="left">
                <div class="title" v-html="notification.description"></div>
                <img :src="notification.icon" class="image" alt="">
            </div>
            <svg width="20" height="21"
                 @click="showBanner=false"
                 class="close-icon"
                 viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M1.19592 1.59814L18.9995 19.4017" stroke="#D3D5D8" stroke-width="2" stroke-linecap="round"/>
                <path d="M1.19635 19.4019L18.9999 1.59826" stroke="#D3D5D8" stroke-width="2" stroke-linecap="round"/>
            </svg>
        </div>
        <div class="header__white-overlay abs bg-white"></div>
        <div class="header flex al-center container j-btw rel ev-all">
            <div class="header__left-col flex al-center" v-if="windowWidth >= 1024" data-cursor-type="hide">
                <div
                    v-if=" !store.selected_menu?.full_url?.includes('apartments')"
                   class="header__item header__item-live-wrapper flex al-center  pointer rel mr-34"
                    data-cursor-type="hide">
                    <div class="lh-0 ev-none" v-html="
                    headerMenuNew?.widgets?.apartments?.svg"></div>

                    <h2 class="f-menu c-white bold uppercase fira f-menu ml-13" @mouseenter="showApartmentsMenu = true" data-cursor-type="hide"> {{
                            headerMenuNew?.widgets?.apartments?.title

                        }}</h2>
                  <transition name="fade">

                    <div class="header__item-live abs" v-if="showApartmentsMenu" @mouseenter="showApartmentsMenu = true"
                         data-cursor-type="hide" @mouseleave="showApartmentsMenu = false">
                      <div class="header__item-live__inner rel flex column al-center bg-white br-5"
                           data-cursor-type="hide">
                        <router-link v-for="(item, index) in headerMenuNew?.apartmentLinks" :to="item?.url"
                                     :key="index" data-cursor-type="hide">
                          <h2 class="f-menu bold uppercase fira f-menu c-navy" data-cursor-type="hide"> {{
                              item?.title

                            }}</h2>
                        </router-link>
                      </div>

                    </div>
                  </transition>
                </div>

                <div class="header__item header__item-live-wrapper flex al-center mr-34 pointer rel"
                     data-cursor-type="hide">
                    <div class="lh-0 ev-none" v-html="
                    headerMenuNew?.widgets?.live?.svg"></div>

                    <h2 class="f-menu c-white bold uppercase fira f-menu ml-13" @mouseenter="showLiveMenu = true"> {{
                            headerMenuNew?.widgets?.live?.title

                        }}</h2>
                    <transition name="fade">


                        <div class="header__item-live abs" v-if="showLiveMenu" @mouseenter="showLiveMenu = true"
                             data-cursor-type="hide" @mouseleave="showLiveMenu = false">
                            <div class="header__item-live__inner rel flex column al-center bg-white br-5"
                                 data-cursor-type="hide">
                                <router-link v-for="(item, index) in headerMenuNew?.live_links" :to="item?.url"
                                             :key="index" data-cursor-type="hide">
                                    <h2 class="f-menu bold uppercase fira f-menu c-navy" data-cursor-type="hide"> {{
                                            item?.title

                                        }}</h2>
                                </router-link>
                            </div>

                        </div>
                    </transition>

                </div>

                <a :href="'tel:' + headerMenuNew?.widgets?.contact?.url" class="header__item flex al-center mr-34"
                   data-cursor-type="hide">
                    <div class="lh-0 ev-none" v-html="
                    headerMenuNew?.widgets?.contact?.svg" data-cursor-type="hide"></div>

                    <h2 class="f-menu c-white bold uppercase fira f-menu ml-13" data-cursor-type="hide"> {{
                            headerMenuNew?.widgets?.contact?.title

                        }}</h2>
                </a>


                <div class="header__fav-icon lh-0 pointer rel" @click="flatStore.setFavoriteListVisibility(true)"
                     v-if="store.selected_menu?.full_url?.includes('apartments')" data-cursor-type="hide">
                    <svg data-cursor-type="hide" width="26" height="22" class="heart-icon" viewBox="0 0 26 22"
                         fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M18.3657 0.760368C16.6606 0.760368 14.9562 1.38739 13.6502 2.65625L12.5294 3.73644L11.4298 2.66662C8.8171 0.129173 4.61117 0.129173 1.99857 2.66662C-0.614106 5.20407 -0.614106 9.38585 1.99857 11.9234C5.27966 15.1103 8.56098 18.2899 11.842 21.4765C12.2349 21.8545 12.8556 21.8545 13.2483 21.4765L23.081 11.9127C25.6901 9.37362 25.6934 5.19348 23.081 2.65589C21.7747 1.38727 20.0706 0.76001 18.3655 0.76001L18.3657 0.760368ZM18.3657 2.7832C19.5599 2.7832 20.7596 3.22852 21.6749 4.11773C23.5057 5.89567 23.5045 8.67071 21.6749 10.4512L12.5395 19.3373C9.49452 16.38 6.44926 13.4193 3.40407 10.4619C1.57334 8.68367 1.57334 5.9064 3.40407 4.12846C5.2348 2.35052 8.18154 2.35024 10.0123 4.12846L11.8307 5.88651H11.831C12.2239 6.26445 12.8446 6.26445 13.2373 5.88651L15.0664 4.1178C15.9817 3.22883 17.1711 2.78327 18.3653 2.78327L18.3657 2.7832Z"
                            fill="#182236"/>
                    </svg>

                    <div class="header__fav-icon--sum flex al-center j-center bg-burgundy abs" data-cursor-type="hide">
                        <span class="fira regular c-white" data-cursor-type="hide">{{ flatStore?.flats.length }}</span>
                    </div>
                </div>

            </div>


            <div class="header__left-col flex al-center" data-cursor-type="hide" v-else>
                <router-link v-if="headerMenuNew?.widgets?.apartments?.url"
                             :to="headerMenuNew?.widgets?.apartments?.url" class="header__item flex al-center "
                             :style="{ display: headerMenuNew?.widgets?.apartments?.svgHover ? 'flex' : 'none' }">
                    <div class="lh-0" v-html="
                    headerMenuNew?.widgets?.apartments?.svgHover"></div>


                </router-link>

                <a :href="'tel:' + headerMenuNew?.widgets?.contact?.url" class="header__item flex al-center mr-34">
                    <div class="lh-0" v-html="
                    headerMenuNew?.widgets?.contact?.svgHover"></div>


                </a>


                <div class="header__fav-icon lh-0 pointer rel mobile-heart"
                     @click="flatStore.setFavoriteListVisibility(true)"
                     v-if="store.selected_menu?.full_url?.includes('apartments')">
                    <svg width="35" height="34" viewBox="0 0 35 34" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <circle opacity="0.3" cx="17.2656" cy="17" r="16" stroke="#8C8C8C" stroke-width="2"/>
                        <path
                            d="M20.9007 11.0002C19.8367 11.0002 18.7731 11.3884 17.9582 12.1739L17.2588 12.8425L16.5726 12.1803C14.9423 10.6095 12.3178 10.6095 10.6876 12.1803C9.05727 13.7511 9.05727 16.3398 10.6876 17.9107C12.735 19.8835 14.7825 21.8518 16.8299 23.8245C17.0751 24.0585 17.4624 24.0585 17.7074 23.8245L23.843 17.9041C25.4711 16.3322 25.4731 13.7445 23.843 12.1736C23.0279 11.3883 21.9645 11 20.9005 11L20.9007 11.0002ZM20.9007 12.2525C21.6458 12.2525 22.3945 12.5281 22.9656 13.0786C24.108 14.1792 24.1073 15.8971 22.9656 16.9993L17.2651 22.5002C15.3651 20.6695 13.4648 18.8367 11.5646 17.0059C10.4222 15.9051 10.4222 14.1859 11.5646 13.0852C12.707 11.9846 14.5458 11.9844 15.6881 13.0852L16.8228 14.1735H16.823C17.0682 14.4075 17.4555 14.4075 17.7005 14.1735L18.8419 13.0786C19.413 12.5283 20.1553 12.2525 20.9004 12.2525L20.9007 12.2525Z"
                            fill="#182236"/>
                    </svg>

                    <div class="header__fav-icon--sum flex al-center j-center bg-burgundy abs">
                        <span class="fira regular c-white">{{ flatStore?.flats.length }}</span>

                    </div>


                </div>
            </div>

            <router-link to="/" class="header__center-col flex al-center abs"
                         :class="{ 'ev-none': store.selected_menu?.url === 'home' }">
                <AllianceLogo/>
            </router-link>

            <div class="header__right-col flex al-center">
                <ThemeSwitch v-if="windowWidth >= 1024"/>


                <LangToggler v-if="windowWidth >= 1024"/>


                <div class="burger-icon pointer" @click="handleBurgerOpen" data-scale-type="1">
                    <span class="bg-white br-10 mb-13"></span>
                    <span class="bg-white br-10"></span>
                </div>


            </div>

        </div>
    </header>
</template>


<style lang="scss">
header {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 99;
    height: 220px;
    transition: .4s;
    // overflow: visible;

    &:before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        background: linear-gradient(0, rgba(17, 25, 41, 0) 0%, #111929 100%);
        z-index: 1;
        height: 100%;
        width: 100%;
        @include easeIn(.3s, all);

    }

    .banner-header-pop-up {
        position: relative;
        background: #2B364D;
        width: 100%;
        height: 55px;
        padding: 21px 55px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        z-index: 99;
        pointer-events: auto;
        @media (max-width: 1023px) {
            padding: 21px;
        }

        .left {
            display: flex;
            align-items: center;

            .image {
                margin-left: 13px;
            }

            .title {
                font-family: 'FiraGO';
                font-style: normal;
                font-weight: 700;
                font-size: 13px;
                line-height: 16px;
                letter-spacing: 0.1em;
                text-transform: uppercase;
                color: white;

                a {
                    color: #F40146;
                }
            }
        }

        .close-icon {
            cursor: pointer;
            @media (max-width: 767px) {
               margin-left: 30px;
            }
        }
    }

    .header {
        height: 100%;
        @include easeInOut(.5s, all);
        // overflow: visible;

        &__fav-icon {
            &--sum {
                border-radius: 50%;
                border: 2px solid $white;
                height: 20px;
                width: 20px;
                top: -4px;
                left: 13px;

                @include mq(tablet-sm) {
                    left: 21px;
                }

                span {
                    font-size: 10px;
                    line-height: 12px;
                }

            }

            svg path {
                @include easeInOut(.5s, all);

            }

            @include hover {
                svg path {
                    fill: $burgundy;
                }
            }
        }

        z-index: 10;

        &__lang {
            span {
                @include easeIn(.5s, all);

            }

            svg path {
                @include easeIn(.5s, all);

            }

        }

        &__item {
            h2 {
                @include easeIn(.5s, all);

            }

            svg path {
                @include easeIn(.5s, all);

            }

            padding-bottom: 4px;


            &-live {
                top: 0px;
                left: 50%;
                transform: translateX(-50%);
                padding-top: 40px;
                z-index: 5;

                a {
                    width: 100%;
                    display: flex;
                    padding-top: 5px;
                    padding-bottom: 5px;
                    justify-content: center;
                    overflow: hidden;

                    @include easeInOut(.4s, all);

                    @include hover {
                        background-color: #E5E5E5;
                    }

                    &:last-child {
                        border-bottom-right-radius: 5px;
                        border-bottom-left-radius: 5px;

                    }


                    padding-left: 6px;
                    padding-right: 6px;

                }

                &__inner {
                    padding-top: 10px;

                    &:before {
                        position: absolute;
                        content: '';
                        top: -10px;
                        left: 50%;
                        transform: translateX(-50%);
                        width: 13px;
                        height: 13px;
                        background: url('../../../assets/imgs/wkenta.svg');
                    }
                }


            }
        }

        &__white-overlay {
            width: 100%;
            height: 100%;
            transform: translateY(-260px);
            @include easeIn(.5s, all);
            box-shadow: 0px 4px 30px rgba(0, 0, 0, 0.1);
            z-index: 10;

        }

        &__center-col {
            @include easeIn(.5s, all);
            left: 50%;
            transform: translateX(-50%) scale(1);

            svg path {
                @include easeIn(.5s, all);

            }
        }


    }

    .burger-icon {
        span {
            display: block;
            height: 2px;
            @include easeIn(.5s, all);

            &:nth-child(1) {
                width: 34px;
                margin-left: 19px;


            }

            &:nth-child(2) {
                width: 55px;
                margin-bottom: 0;
            }
        }

    }


    &.header-scrolled,
    &.header-opaque {
        background: initial;

        &:before {
            opacity: 0;
            @include easeOut(.3s, all);
        }


        .header {
            transform: translateY(-27px);

            &__white-overlay {
                width: 100%;
                height: 100%;
                transform: translateY(-57px);
                @include easeOut(.5s, all);


            }

            &__item h2,
            &__item svg path,
            &__lang span,
            &__lang svg path,
            &__center-col svg path,
            .burger-icon span {
                transition-delay: 250ms;
            }

            &__item {
                position: relative;

                h2 {
                    color: $primaryNavy;
                    @include easeOut(.5s, all);

                }


                svg {
                    path {
                        fill: $burgundy;
                        @include easeOut(.5s, all);

                        @include mq(tablet-sm) {
                            fill: $primaryNavy;

                        }
                    }

                    circle {
                        stroke: $midSilver;
                    }
                }

                overflow: hidden;

                &:after {
                    content: '';
                    position: absolute;
                    width: 100%;
                    height: 1px;
                    bottom: 0px;
                    left: 0;
                    background-color: $primaryNavy;
                    transform: translateX(-100%);
                    transition: .7s cubic-bezier(0.575, 0.005, 0.285, 1.005) all;
                }

                @include hover {
                    &:after {
                        transform: translateX(0);

                    }
                }

                &-live-wrapper {
                    overflow: visible;

                    &:after {
                        display: none !important;
                    }
                }

            }

            &__lang {
                span {
                    color: $primaryNavy;
                    @include easeOut(.5s, all);

                }

                svg path {
                    stroke: $burgundy;
                    @include easeOut(.5s, all);

                }
            }

            &__center-col {
                transform: translateX(-50%) scale(0.8);
                @include easeOut(.5s, all);

                // transition-delay: 20ms;
                svg path {
                    fill: #25313D;
                    @include easeOut(.5s, all);

                }
            }

            .burger-icon {
                span {

                    background-color: $primaryNavy;
                    @include easeOut(.5s, all);

                }

            }
        }


        .el-switch {

            background-color: rgba(41, 54, 82, 0.2) !important;
            border-color: rgba(166, 166, 166, 0.5) !important;

            &__action {
                background-color: $blackBlue !important;
            }

            &__core {

                background-color: transparent !important;
                background: transparent !important;
                border: none !important;

                border-color: initial !important;

            }

            &.is-checked {
                background-color: rgba(41, 54, 82, 0.5) !important;

                .el-switch__core {
                    background-color: transparent !important;
                    border-color: initial !important;
                    background: transparent !important;
                    border: none !important;


                }

                .el-switch__action {
                    background-color: white !important;


                }

            }

        }

        .header__lang {
            a {
                color: $primaryNavy !important;
            }
        }


    }

    &.header-opaque {
        @include mq(tablet-sm) {
            .header__center-col {
                transform: scale(0.62) translateX(-65%) translateY(-46px) !important;
            }
        }
    }

    &.header-opaque.header-scrolled {
        .header {

            transform: translateY(-55px);
        }

        .header__white-overlay {

            transform: translateY(-110px);
        }

        .header__center-col {
            transform: translateX(-50%) scale(0.51);
        }

        @include mq(desktop-sm) {
            header {
                height: auto;
            }

            .header {

                transform: translateY(-15px);
            }

            .header__white-overlay {

                transform: translateY(-27px);
            }

            .header__center-col {
                transform: translateX(-50%) scale(0.81);

            }


        }

        @include mq(tablet-sm) {
            .header__center-col {
                transform: scale(0.42) translateX(-95%) translateY(-66px) !important;
                // svg {
                //     transform: scale(0.58);
                // }
            }
        }

        @include mq(mobile) {
            .header__center-col {
                transform: scale(0.62) translateX(-65%) translateY(-46px) !important;

            }

            .header__white-overlay, .header {
                transform: translateY(0px) !important;

            }
        }
    }

    @include mq(desktop-sm) {
        height: 114px;

        .header {


            &__white-overlay {
                transform: translateY(-125px);

            }

            &__center-col {
                svg {
                    height: 74px;
                    width: 55px;
                }
            }

        }

        &.header-scrolled,
        &.header-opaque {
            .header {
                transform: translateY(0);

                &__center-col {
                    transform: translateX(-50%) scale(1);
                }

                &__white-overlay {
                    transform: translateY(0px);

                }
            }

        }

        // &.header-opaque {
        //     .header__center-col {

        // transform: scale(0.62) translateX(-65%) translateY(-46px);
        //     }
        // }

    }

    @include mq(tablet) {
        position: fixed;


    }

    @include mq(tablet-sm) {
        height: 103px;

        &.ov-hidden {

            overflow: visible;
        }

        .header {
            &__item:not(:last-child) {
                margin-right: 8px !important;
            }

            &__white-overlay {
                transform: translateY(-175px);

            }


            &__center-col {
                top: 21px;

                // transform-origin: center ;
                svg {
                    height: 120px;
                    width: 89px;
                }
            }


        }

        &.header-scrolled {
            // height: 103px;

            .header {

                // &__white-overlay {
                //     // height: 103px;
                // }

                &__center-col {
                    transform: scale(0.62) translateX(-65%) translateY(-46px);
                }
            }
        }

    }


    @include mq(mobile) {
        .header {
            &__center {
                svg {
                    width: 89px;
                    height: 120px;
                }
            }
        }

    }
}
</style>
<style lang="scss">
body {
    &.is-dark {
        .header__fav-icon {
            .heart-icon {
                path {
                    fill: white;
                }
            }

            &.mobile-heart {
                path {
                    // stroke: $burgundy !important;
                    stroke: $white;
                }
            }
        }
    }
}
</style>
