/**
 * @author Irakli Kandelaki
 */

import { handleResize } from "./resizeHandler";

/**
 * @summary ბექგრაუნდის შემცვლელი მუქ ფონზე, როცა ვიუპორტში გამოჩნდება კომპონენტი, ცვლის ბექგრაუნდს.
 */

const { windowHeight, windowWidth } = handleResize();

export default (store: any): void => {
    if (store.isDark) return;
    const parent = document.querySelector<HTMLElement>("#app");
    if (windowWidth.value <= 1024) {
        parent.addEventListener("scroll", () => {
            const els =
                document.querySelectorAll<HTMLElement>(".toggle-to-dark");
            if (!els.length) return;

            els.forEach((el) => {
                const elTop: number = el.getBoundingClientRect().top;

                const isElVisible: boolean =
                    elTop - windowHeight.value <= -500 &&
                    Math.abs(elTop - windowHeight.value) <=
                        el.offsetHeight + 1200
                        ? true
                        : false;
                if (!isElVisible) {
                    store.isBgDark = false;
                } else {
                    store.isBgDark = true;
                }
            });
        });
    } else {
        const els = document.querySelectorAll<HTMLElement>(".toggle-to-dark");
        if (!els.length) return;
        els.forEach((el) => {
            const elTop: number = el.getBoundingClientRect().top;

            const isElVisible: boolean =
                elTop - windowHeight.value <= -500 &&
                Math.abs(elTop - windowHeight.value) <= el.offsetHeight + 1200
                    ? true
                    : false;
            if (!isElVisible) {
                store.isBgDark = false;
            } else {
                store.isBgDark = true;
            }
        });
    }
};
